import React from "react"

const YM = () => {

    return (
     <div dangerouslySetInnerHTML={{__html:
            '<script type=\'text/javascript\' >\n' +
            '            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n' +
            '            m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n' +
            '            (window, document, \'script\', \'https://mc.yandex.ru/metrika/tag.js\', \'ym\');\n' +
            '            ym(63328183, \'init\', {\n' +
            '            clickmap:true,\n' +
            '            trackLinks:true,\n' +
            '            accurateTrackBounce:true,\n' +
            '            webvisor:true\n' +
            '        });\n' +
            '        </script>\n' +
            '    <noscript><div><img src=\'https://mc.yandex.ru/watch/63328183\' style=\'position:absolute; left:-9999px;\' alt=\'\' /></div></noscript>'
    }}/>
    );
};


export default YM
