import React from "react"
import styles from "../button.module.css"
import {Link} from "gatsby";

const LinkButton = ({text, path}) => {

    return (
        <Link to={path}>
            <button className={styles.button}>{text}</button>
        </Link>
    );
};


export default LinkButton
